import m from 'mithril';
import DOMPurify from 'dompurify';

import { UPRILoader } from './UPRILoader';
import '../css/components/search_bar_view.scss';

import Snackbar from 'js-snackbar/dist/js-snackbar';

export class SearchBarView {
    oncreate(vnode) {
        // Update search bar
        if(vnode.attrs.inputValue) {
            vnode.dom.querySelector('input[name=q]').value = vnode.attrs.inputValue ?? '';
            
            this.populateFilters(vnode);
        }
    }

    view(vnode) {
        return m('div', [
            m('form#concise-search', {method: 'get', onsubmit: this.onSubmitSearch}, [
                m('div.search-bar', [
                    m('input', {name: 'q', type: 'text', placeholder: 'Search...'}),
                    m('button.pseudo.icon', {type: 'submit'}, [
                        m('i.material-icons', 'search'),
                    ]),
                ]),
            ]),
            m('form#search-filters', [
                m('label.filter-set', {onclick: () => this.populateFilters(vnode), for: 'cbx-search-filter-modal'}, [
                    m('i.material-icons', 'filter_alt'),
                    'Filters',
                ]),
                m('div#search-filter-modal.modal', [
                    m('input#cbx-search-filter-modal', {type: 'checkbox', onchange: () => this.onModalViewChange(vnode)}),
                    m('label.overlay', {for: 'cbx-search-filter-modal'}),
                    m('article', [
                        m('header', [
                            m('h3', 'Search Filters'),
                            m('label.close', {for: 'cbx-search-filter-modal'}, [
                                m('i.material-icons', 'close'),
                            ]),
                        ]),
                        m('section.loader.content', [
                            m(UPRILoader),
                        ]),
                        m('section.content', [
                            m('button', {type: 'button', onclick: (evt) => this.onResetFilters(vnode)}, 'Reset and Close'),
                            m('div', [
                                this.geoscopes?.length > 0
                                    ? [
                                        m('h5', [
                                            'Political Scope'
                                        ]),
                                        m('div.flex.one.two-600.three-1000', [
                                            ...this.geoscopes.map((v) => {
                                                return m('label', [
                                                    m('input', {type: 'radio', name: 'filter-geoscopes', value: v}),
                                                    m('span.checkable', v),
                                                ]);
                                            }),
                                        ]),
                                    ]
                                    : [],
                            ]),
                            m('div', [
                                this.doctypes?.length > 0
                                ? [
                                    m('h5', [
                                        'Document Types'
                                    ]),
                                    m('div.flex.one.two-600.three-1000', [
                                        ...this.doctypes.map((v) => {
                                            return m('label', [
                                                m('input', {type: 'checkbox', name: 'filter-doctypes', value: v}),
                                                m('span.checkable', v),
                                            ]);
                                        }),
                                    ]),
                                ]
                                : [],
                            ]),
                            m('div', [
                                this.sectors?.length > 0
                                ? [
                                    m('h5', [
                                        'Sectors'
                                    ]),
                                    m('div.flex.one.two-600.three-1000', [
                                        ...this.sectors?.map((v) => {
                                            return m('label', [
                                                m('input', {type: 'checkbox', name: 'filter-sectors', value: v}),
                                                m('span.checkable', v),
                                            ]);
                                        }) ?? [],
                                    ]),
                                ]
                                : [],
                            ]),
                            m('div', [
                                this.themes?.length > 0
                                ? [
                                    m('h5', [
                                        'Themes',
                                    ]),
                                    m('div.flex.one.two-600.three-1000', [
                                        ...this.themes.map((v) => {
                                            return m('label', [
                                                m('input', {type: 'checkbox', name: 'filter-themes', value: v}),
                                                m('span.checkable', v),
                                            ]);
                                        }),
                                    ]),
                                ]
                                : [],
                            ]),
                        ]),
                    ]),
                ]),
            ])
        ]);
    }

    onSubmitSearch(evt) {
        evt.preventDefault();

        const txtSearch = evt.currentTarget.querySelector('input[name="q"]');

        if(txtSearch.value.length > 0) {
            m.route.set(m.buildPathname('/search', {q: DOMPurify.sanitize(txtSearch.value)}));
        }
        else {
            Snackbar({
                message: 'Please enter a search term',
                timeout: 3000,
                status: 'warn',
                icon: 'warn',
                dismissible: true,
                fixed: true,
            });
        }
    }

    onModalViewChange(vnode) {
        const dom = vnode.dom;
        const presenter = vnode.attrs.presenter;

        const modalCbxElm = dom.querySelector('#cbx-search-filter-modal');
        const searchBarElm = dom.querySelector('input[name=q]');

        if(!modalCbxElm.checked) {
            // Hide modal
            // Update search textbox with filter tags
            const checkGeoscope = Array.from(dom.querySelectorAll('#search-filter-modal input[type=radio][name=filter-geoscopes]:checked').values()).map((v) => v.value);
            const checkedDoctypes = Array.from(dom.querySelectorAll('#search-filter-modal input[type=checkbox][name=filter-doctypes]:checked').values()).map((v) => v.value);
            const checkedSectors = Array.from(dom.querySelectorAll('#search-filter-modal input[type=checkbox][name=filter-sectors]:checked').values()).map((v) => v.value);
            const checkedThemes = Array.from(dom.querySelectorAll('#search-filter-modal input[type=checkbox][name=filter-themes]:checked').values()).map((v) => v.value);

            const newVal = presenter.diffSearchBarTags(searchBarElm.value, {gs: checkGeoscope, dtyp: checkedDoctypes, sec: checkedSectors, thm: checkedThemes});

            searchBarElm.value = (newVal.trim().length > 0) ? newVal.trim() : null;
        }
        else {
            // Show modal
            // Update filter modal with filter tags
            const currentSearchVal = searchBarElm.value;
            const filters = presenter.getFiltersFromText(currentSearchVal);
            let isGsFilterMatched = false;

            for(const eachElm of dom.querySelectorAll('#search-filter-modal input[type=radio][name=filter-geoscopes]')) {
                isGsFilterMatched = filters['gs'].some((v) => v.toLowerCase() == eachElm.value.toLowerCase());

                eachElm.checked = isGsFilterMatched;

                if(isGsFilterMatched) {
                    // Match only first element
                    break;
                }
            }

            for(const eachElm of dom.querySelectorAll('#search-filter-modal input[type=checkbox][name=filter-doctypes]')) {
                eachElm.checked = filters['dtyp'].some((v) => v.toLowerCase() == eachElm.value.toLowerCase());
            }

            for(const eachElm of dom.querySelectorAll('#search-filter-modal input[type=checkbox][name=filter-sectors]')) {
                eachElm.checked = filters['sec'].some((v) => v.toLowerCase() == eachElm.value.toLowerCase());
            }

            for(const eachElm of dom.querySelectorAll('#search-filter-modal input[type=checkbox][name=filter-themes]')) {
                eachElm.checked = filters['thm'].some((v) => v.toLowerCase() == eachElm.value.toLowerCase());
            }
        }
    }

    onResetFilters(vnode) {
        vnode.dom.querySelector('#search-filters').reset();
        this.onModalViewChange(vnode);
    }

    async populateFilters(vnode) {
        const thisObj = vnode.state;
        const presenter = vnode.attrs.presenter;

        const loaderElm = vnode.dom.querySelector('#search-filter-modal section.loader.content');

        if((thisObj.doctypes?.length ?? -1) <= 0) {
            thisObj.doctypes = await presenter.fetchDoctypes();
        }

        if((thisObj.geoscopes?.length ?? -1) <= 0) {
            thisObj.geoscopes = await presenter.fetchGeoscopes();
        }

        if((thisObj.sectors?.length ?? -1) <= 0) {
            thisObj.sectors = await presenter.fetchSectors();
        }

        if((thisObj.themes?.length ?? -1) <= 0) {
            thisObj.themes = await presenter.fetchThemes();
        }

        loaderElm.classList.add('hide');

        m.redraw();
    }
}