import m from 'mithril';

import '../css/components/navbar_view.scss';
import upriLogo from '../../static/org_icons/upri_logo.png';
import sbookLogo from '../../static/org_icons/sbook_notext.svg';

export class NavbarView {
    constructor() {
        this.links = [
            {
                name: 'About',
                href: '/about',
            },
            {
                name: 'Database',
                href: '/database',
            },
            {
                name: 'Feedback',
                href: '/feedback',
            },
        ];
    }

    oncreate(vnode) {
        const navbarHeightPx = window.outerHeight * (11 / 100) + 6;
        this.lastScrollTop = 0;

        window.addEventListener('scroll', () => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if(scrollTop < this.lastScrollTop) {
                // Scroll up
                if(scrollTop == 0) {
                    // Reset layout when top of page is reached
                    //vnode.dom.style.position = 'relative';
                }

                vnode.dom.style.top = 0;
            }
            else if(scrollTop >= navbarHeightPx) {
                // Scroll down
                vnode.dom.style.top = 'calc(-5rem - 6px)';
                //vnode.dom.style.position = 'fixed';
            }

            this.lastScrollTop = scrollTop;
        });
    }

    view(vnode) {
        return m('nav#main-nav', [
            m(m.route.Link, {selector: 'a.brand', href: '/'}, [
                m('img.logo-img', {alt: 'UP Resilience Institute', src: upriLogo}),
                m('img.logo-img', {alt: 'COPE Sourcebook', src: sbookLogo}),
                //m('img.logo-text', {alt: 'UP Resilience Institute', sizes: '10vw', srcset: 'static/upri_logo_text.png 210w'}),
                m('span', 'Sourcebook'),
            ]),
            m('input#nav-b.show', {type: 'checkbox'}),
            m('label', {for: 'nav-b', class: 'burger pseudo button'}, [
                m('i.material-icons', 'menu'),
            ]),
            m('div.menu', this.links.map((v, k) => {
                if(k == vnode.attrs.activeIdx) {
                    return m(m.route.Link, {selector: 'a.button.active', href: v.href}, v.name);
                }
                else {
                    return m(m.route.Link, {selector: 'a.button', href: v.href}, v.name);
                }
            })),
        ]);
    }
}