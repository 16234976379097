import m from 'mithril';
import Glide, { Controls as GlideControls, Breakpoints as GlideBreakpoints, Swipe as GlideSwipe } from '@glidejs/glide/dist/glide.modular.esm';

import '@glidejs/glide/dist/css/glide.core.min.css';
import '../css/components/glide_carousel.scss';

export class GlideCarousel {
    oncreate(vnode) {
        // Init carousel
        this.carouselObj = new Glide(vnode.dom, {
            type: 'carousel',
            focusAt: 'center',
            ...vnode.attrs.options,
        });

        this.carouselObj.mount({ GlideControls, GlideBreakpoints, GlideSwipe });
    }

    view(vnode) {
        return m('div.glide', [
            m('div.glide__track[data-glide-el=track]', [
                m('ul.glide__slides', [
                    ...vnode.attrs.slides.map((v) => {
                        return m('li.glide__slide', v);
                    }),
                ]),
            ]),
            m('div.glide__arrows[data-glide-el=controls]', [
                m('button.glide__arrow.glide__arrow--left[data-glide-dir=<]', [
                    m('i.material-icons', 'arrow_left'),
                ]),
                m('button.glide__arrow.glide__arrow--right[data-glide-dir=>]', [
                    m('i.material-icons', 'arrow_right'),
                ]),
            ]),
            m('div.glide__bullets[data-glide-el="controls[nav]"]', [
                ...vnode.attrs.slides.map((_, i) => {
                    return m(`button.glide__bullet[data-glide-dir="=${i}"]`);
                }),
            ]),
        ]);
    }
}