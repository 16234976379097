import m from 'mithril';

export class FooterView {
    constructor() {
        this.links = [
            {
                name: 'UP Resilience Institute',
                href: '//resilience.up.edu.ph'
            },
            {
                name: 'NOAH',
                href: '//noah.up.edu.ph'
            },
            {
                name: 'EndCOV',
                href: '//endcov.up.edu.ph'
            }
        ];
    }

    view() {
        return m('footer', [
            m('p.copy-notice', 'Copyright © University of the Philippines 2022'),
            m('div.links', this.links.map((v) => {
                return m('a', {href: v.href, target: '_blank'}, v.name);
            })),
        ]);
    }
}